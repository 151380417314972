<template>
  <card :title="$t('speakers.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('speakers.created')" />

      <speaker-form :form="form"></speaker-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('create') }}
          </v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
  import Form from 'vform'
  import SpeakerForm from './form'
  import { objectToFormData } from 'object-to-formdata'

  export default {
    scrollToTop: false,
    components: {
      'speaker-form': SpeakerForm
    },
    data: () => ({
      form: new Form({
        name: '',
        thumbnailUrl: '',
        thumbnail: null,
      })
    }),
    methods: {
      async create() {
        const { data } = await this.form.submit('post', '/api/v1/user/me/speakers', {
          // Transform form data to FormData
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        })
        
        this.$store.dispatch('account/updateSpeakers', { speaker: data.speaker })
          .then(() => this.$router.push({ name: 'accounts.speakers.edit', params: {id: data.speaker.id }}))
        
      }
    }
  }
</script>
